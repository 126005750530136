import React, { useState } from 'react';
import axios from 'axios';
import { Box, Typography, TextField, IconButton, InputAdornment, Snackbar, Alert, Button } from '@mui/material';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import WarningIcon from '@mui/icons-material/Warning';
import { useTheme } from '@mui/material/styles';

const UTMViewer = ({ utm }) => {
  const theme = useTheme();
  const [isUTMCopied, setIsUTMCopied] = useState(false);
  const [isShortenedURLCopied, setIsShortenedURLCopied] = useState(false);
  const [shortenedURL, setShortenedURL] = useState('');
  const [isShortening, setIsShortening] = useState(false);
  const [openSnackbar, setOpenSnackbar] = useState(false);

  const shortenURL = () => {
    if (utm) {
      setIsShortening(true);
      // Ensure the request body key matches your API's expected schema
      axios.post('https://5sog9yhjqc.execute-api.us-east-2.amazonaws.com/dev/shortenurl', { url: utm })
        .then((response) => {
          // Access the shortened URL using the key that your API responds with
          setShortenedURL(response.data.shortenedUrl);
          setIsShortening(false);
        })
        .catch((error) => {
          console.error('Error shortening URL: ', error);
          setShortenedURL('Failed to shorten URL');
          setIsShortening(false);
        });
    }
  };

  const handleCopyClick = (text, callback) => () => {
    navigator.clipboard.writeText(text)
      .then(() => {
        callback(true);
        setOpenSnackbar(true);
        setTimeout(() => callback(false), 2000); // Reset after 2 seconds
      })
      .catch(err => {
        console.error('Failed to copy text: ', err);
      });
  };

  const handleCloseSnackbar = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpenSnackbar(false);
  };

  const isUTMComplete = utm && utm.length > 0;

  const commonStyles = {
    backgroundColor: '#ffffff',
    border: '1px solid #d9d9d9',
    borderRadius: '4px',
    p: 2,
    fontSize: '14px',
    lineHeight: 1.5,
    color: '#595959',
    mt: 2,
  };

  if (!isUTMComplete) {
    return (
      <Box sx={commonStyles} display="flex" alignItems="center">
        <WarningIcon color="error" sx={{ mr: 1 }} />
        <Typography variant="body2" color="error">
          Please fill out all required fields to generate your Campaign URL.
        </Typography>
      </Box>
    );
  }

  return (
    <Box sx={commonStyles}>
      <Typography variant="h6" gutterBottom>
        Your New Campaign URL
      </Typography>
      <Typography variant="body2" paragraph>
        Below is your generated URL with the CID parameters. You can copy this URL and use it in your campaigns to track performance.
      </Typography>
      <TextField
        label="Generated URL"
        variant="outlined"
        fullWidth
        multiline
        minRows={1}
        InputProps={{
          readOnly: true,
          startAdornment: (
            <InputAdornment position="start">
              <IconButton onClick={handleCopyClick(utm, setIsUTMCopied)}>
                {isUTMCopied ? <CheckCircleIcon color="success" /> : <ContentCopyIcon />}
              </IconButton>
            </InputAdornment>
          ),
        }}
        value={utm}
      />
      <Box sx={{ mt: 2, width: '70%', display: 'flex', alignItems: 'center' }}> {/* Adjust the width as needed */}
        <TextField
          label="Shortened URL"
          variant="outlined"
          fullWidth
          value={shortenedURL || ''}
          InputProps={{
            readOnly: true,
            placeholder: shortenedURL ? '' : 'Click shorten URL to get your shortened link', // The placeholder text will only be shown when shortenedURL is empty
            startAdornment: (
              <InputAdornment position="start">
                <IconButton onClick={handleCopyClick(shortenedURL, setIsShortenedURLCopied)}>
                  {isShortenedURLCopied ? <CheckCircleIcon color="success" /> : <ContentCopyIcon />}
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
        <Button 
          variant="contained" 
          onClick={shortenURL} 
          sx={{ width: 'auto', minWidth: '175px', ml: 2, px: 4, height: '40px', backgroundColor: '#7851A9', }}  
          disabled={isShortening || !isUTMComplete}
        >
          {isShortening ? 'Shortening...' : 'Shorten URL'}
        </Button>
      </Box>
      <Snackbar open={openSnackbar} autoHideDuration={1000} onClose={handleCloseSnackbar} anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}>
        <Alert onClose={handleCloseSnackbar} severity="success" sx={{ width: '100%' }}>
          Copied to Clipboard!
        </Alert>
      </Snackbar>
    </Box>
  );
}

export default UTMViewer;
