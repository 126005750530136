import React, { useState, useEffect } from 'react';
import {
  Dialog, DialogTitle, DialogContent, DialogActions,
  Button, FormControl, FormGroup, FormControlLabel,
  Checkbox, InputLabel, Select, MenuItem, CircularProgress
} from '@mui/material';

const isProduction = process.env.NODE_ENV === 'production'; // Environment check

const DependencyManager = ({ open, onClose, onSave, fields, currentRow, optionsForDependentField, loading }) => {
  const [selectedDependentField, setSelectedDependentField] = useState('');
  const [selectedOptions, setSelectedOptions] = useState([]);

useEffect(() => {
  console.log('Dependency Manager Opened:', open);
  console.log('Current row:', currentRow);
  console.log('Fields:', fields);
  console.log('Current row dependencies:', currentRow.dependencies);

  if (open && fields.length > 0) {
    // Find the first field with dependencies
    const fieldWithDependencies = fields.find(field => currentRow.dependencies && currentRow.dependencies[field] && currentRow.dependencies[field].length > 0);

    if (fieldWithDependencies) {
      console.log('Field with dependencies:', fieldWithDependencies);
      setSelectedDependentField(fieldWithDependencies);
      
      // Set the selected options for this field
      const dependencyLabels = currentRow.dependencies[fieldWithDependencies].map(dep => dep.label);
      console.log('Dependency Labels:', dependencyLabels);
      setSelectedOptions(dependencyLabels);
    } else {
      // No dependencies, select the first field and reset options
      setSelectedDependentField(fields[0]);
      setSelectedOptions([]);
      console.log('No dependencies found, resetting to first field and options.');
    }
  }
}, [open, currentRow, fields]);

  const handleSave = () => {
      if (isProduction) {
      console.log('Save action disabled in production');
      return; // Disable save in production
    }

      // Transform the selected options into the expected format
      const transformedSelectedOptions = selectedOptions.map(optionLabel => {
          const fullOption = optionsForDependentField[selectedDependentField].find(option => option.label === optionLabel);
          return fullOption || { label: optionLabel, hint: '' };
      });

      // Construct updated dependencies
      const updatedDependencies = {
          ...currentRow.dependencies,
          [selectedDependentField]: transformedSelectedOptions
      };

      // Call onSave with the updated dependencies
      onSave(currentRow.label, selectedDependentField, transformedSelectedOptions);
      onClose();
  };



  const handleOptionChange = (optionLabel) => {
    const updatedOptions = selectedOptions.includes(optionLabel)
      ? selectedOptions.filter(opt => opt !== optionLabel)
      : [...selectedOptions, optionLabel];
    setSelectedOptions(updatedOptions);
  };

  const renderOptions = () => {
    if (!selectedDependentField || !optionsForDependentField || !Array.isArray(optionsForDependentField[selectedDependentField])) {
      console.error('Required data not available or not in correct format.');
      return null;
    }

    return optionsForDependentField[selectedDependentField].map(option => (
      <FormControlLabel
        control={<Checkbox checked={selectedOptions.includes(option.label)} onChange={() => handleOptionChange(option.label)} />}
        label={option.label}
        key={option.label}
      />
    ));
  };

  if (loading) {
    return (
      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
        <CircularProgress />
      </div>
    );
  }

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Manage Dependencies for: {currentRow.label}</DialogTitle>
      <DialogContent>
        <FormControl fullWidth margin="normal">
          <InputLabel>Dependent Field</InputLabel>
          <Select
            value={selectedDependentField}
            onChange={(e) => setSelectedDependentField(e.target.value)}
            label="Dependent Field"
          >
            {fields.map(field => (
              <MenuItem key={field} value={field}>{field}</MenuItem>
            ))}
          </Select>
        </FormControl>

        {selectedDependentField && renderOptions()}
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cancel</Button>
        {!isProduction && <Button onClick={handleSave}>Save</Button>}
      </DialogActions>
    </Dialog>
  );
};

export default DependencyManager;
