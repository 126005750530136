import { db } from '../firebase'; // Assuming db is exported from firebase.js
import { collection, getDocs, doc, getDoc, setDoc, onSnapshot } from 'firebase/firestore';

export const getDropdownOptions = async () => {
  const options = {};
  const dropdownOptionsCol = collection(db, 'dropdownOptions');
  const snapshot = await getDocs(dropdownOptionsCol);
  snapshot.forEach(docSnapshot => {
    options[docSnapshot.id] = docSnapshot.data().options;
  });
  return options;
};

export const subscribeToDropdownOptions = (callback) => {
  const dropdownOptionsCol = collection(db, 'dropdownOptions');
  return onSnapshot(dropdownOptionsCol, (snapshot) => {
    const options = {};
    snapshot.forEach(docSnapshot => {
      options[docSnapshot.id] = docSnapshot.data().options;
    });
    callback(options);
  });
};

export const updateDropdownOptions = async (category, newOptions) => {
  console.log(`Updating dropdown options for '${category}' with data:`, newOptions);

  const optionsDoc = doc(db, 'dropdownOptions', category);

  try {
    await setDoc(optionsDoc, { options: newOptions });
    console.log(`Successfully updated options for '${category}'`);
  } catch (error) {
    console.error(`Error updating options for '${category}':`, error);
    throw error; // Rethrowing the error so it can be caught in handleSave
  }
};


export const getDropdownDependencies = async () => {
  try {
    const docRef = doc(db, 'dropdownDependencies', 'dynamicDependencies');
    const docSnapshot = await getDoc(docRef);
    
    if (docSnapshot.exists()) {
      return docSnapshot.data();
    } else {
      console.error('No dynamicDependencies found in Firestore!');
      return {};
    }
  } catch (error) {
    console.error('Error fetching dynamic dependencies:', error);
    return {};
  }
};

export const subscribeToDropdownDependencies = (callback) => {
  const dependenciesDoc = doc(db, 'dropdownDependencies', 'dynamicDependencies');
  return onSnapshot(dependenciesDoc, (docSnapshot) => {
    if (docSnapshot.exists()) {
      callback(docSnapshot.data());
    }
  });
};

export const updateDropdownDependencies = async (newDependencies) => {
  console.log("Updating dropdown dependencies with data:", newDependencies);

  const dependenciesDoc = doc(db, 'dropdownDependencies', 'dynamicDependencies');

  try {
    await setDoc(dependenciesDoc, newDependencies, { merge: true });
    console.log("Successfully updated dropdown dependencies");
  } catch (error) {
    console.error("Error updating dropdown dependencies:", error);
    throw error; // Rethrowing the error so it can be caught in handleSave
  }
};

