//EditableTable.js
import { doc, setDoc } from 'firebase/firestore';
import { db } from '../../firebase'; // Adjust the path to where your Firestore instance is initialized
import React, { useState, useEffect, useRef } from 'react';
import { DataGrid, GridActionsCellItem } from '@mui/x-data-grid';
import styles from './EditableTable.module.css';
import { Button, Snackbar, Alert } from '@mui/material';
import AccountTreeIcon from '@mui/icons-material/AccountTree'; // Default (outlined) version
import AccountTreeFilledIcon from '@mui/icons-material/AccountTree'; // Filled version
import DependencyManager from './DependencyManager';
import { updateDropdownOptions, getDropdownOptions, getDropdownDependencies } from '../../utils/firestore';
import CustomToolbar from './CustomToolbar';
import { useTheme } from '@mui/material/styles';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';


const EditableTable = ({ initialData, title, allFields, category, onDataChange }) => {
   //console.log("Initial data received:", initialData); // Add this line

    const dataGridRef = useRef(null); 
    const theme = useTheme();
    const [rows, setRows] = useState([]);
    const [currentRow, setCurrentRow] = useState(null);
    const [openDependencyManager, setOpenDependencyManager] = useState(false);
    const [dropdownOptions, setDropdownOptions] = useState({});
    const [isDeleteMode, setIsDeleteMode] = useState(false);
    const [error, setError] = useState('');

// Fetch dropdown options and dependencies
useEffect(() => {
  //  console.log("useEffect for fetching data initiated");

    const fetchData = async () => {
        try {
        //    console.log("Fetching dropdown options and dependencies");

            const options = await getDropdownOptions();
            setDropdownOptions(options);
        //    console.log("Dropdown options set:", options);

            const dependencies = await getDropdownDependencies();

            // Ensure that each item in initialData has an 'id' and 'label' property
            const validatedData = initialData.filter(item => item && item.label);

            const updatedRows = validatedData.map(item => ({
                ...item,
                id: item.id || item.label,
                dependencies: dependencies.dynamicDependencies && dependencies.dynamicDependencies[item.label] ? dependencies.dynamicDependencies[item.label] : {}
            }));
            setRows(updatedRows);
        //    console.log("Rows set:", updatedRows);

        } catch (err) {
            console.error('Error while fetching data:', err);
            setError('Failed to fetch data: ' + err.message);
        }
    };
    fetchData();
}, [initialData]);


    useEffect(() => {
        const handleClickOutside = (event) => {
            if (dataGridRef.current && !dataGridRef.current.contains(event.target)) {
                setIsDeleteMode(false);
            }
        };

        document.addEventListener('mousedown', handleClickOutside);

        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [dataGridRef]);

    // Function to transform row data before sending to Firestore
    const transformRowsForFirestore = (rows) => {
        const transformed = rows.map(({ label, hint }) => ({ label, hint }));
        return transformed;
    };

    const processRowUpdate = (newRow) => {
        const updatedRows = rows.map(row => (row.id === newRow.id ? { ...newRow, isNewRow: false } : row));
        setRows(updatedRows);
        console.log("Rows state updated in processRowUpdate:", updatedRows); // Log here

        const transformedData = transformRowsForFirestore(updatedRows);
        onDataChange(category, transformedData);
        return newRow;
    };

// Modify the handleAddRow function
const handleAddRow = () => {
    const newId = Date.now();
    const newRow = { 
        id: newId, 
        label: 'Click to add new option', 
        hint: '', 
        dependencies: {}, 
        isNewRow: true 
    };

    const updatedRows = [newRow, ...rows];
    setRows(updatedRows);
    console.log("Rows state updated in handleAddRow:", updatedRows); // Log here


    // Transform rows before sending to parent
    const transformedData = transformRowsForFirestore(updatedRows);
    onDataChange(category, transformedData);
};

    const handleCellEditStart = (params, event) => {
        if (params.field === 'label' && params.row.isNewRow) {
            const updatedRows = rows.map(row => {
                if (row.id === params.id) {
                    return { ...row, label: '', isNewRow: false };
                }
                return row;
            });
            setRows(updatedRows);
            onDataChange(category, updatedRows); // Notify parent component about the change

        }
    };

    const handleOpenDependencyManager = (row) => {
        if (!dropdownOptions || Object.keys(dropdownOptions).length === 0) {
            setError('Dropdown options are not available.');
            return;
        }

        setCurrentRow(row);
        setOpenDependencyManager(true);
    };

    const handleCloseDependencyManager = () => {
        setOpenDependencyManager(false);
    };

    const handleDependencyUpdate = async (sourceField, dependentField, selectedOptions) => {
        try {
            
            // Fetch current dynamicDependencies from Firestore
            const currentDependenciesDoc = await getDropdownDependencies();
            const currentDependencies = currentDependenciesDoc.dynamicDependencies || {};


            // Check if selectedOptions is an array before mapping
            if (!Array.isArray(selectedOptions)) {
                console.error('Selected options is not an array:', selectedOptions);
                return; // Exit the function if selectedOptions is not an array
            }

            const updatedOptions = selectedOptions.map(option => 
                (typeof option === 'string') ? { label: option, hint: '' } : option
            );


            // Update the dependencies for the specific source field
            let updatedSourceFieldDependencies = {
                ...(currentDependencies[sourceField] || {}),
                [dependentField]: updatedOptions
            };

            // Remove the dependent field from dependencies if no options are selected
            if (updatedOptions.length === 0) {
                delete updatedSourceFieldDependencies[dependentField];
            }

            // Remove the source field from dependencies if it has no dependent fields left
            if (Object.keys(updatedSourceFieldDependencies).length === 0) {
                delete currentDependencies[sourceField];
            } else {
                currentDependencies[sourceField] = updatedSourceFieldDependencies;
            }


            // Update Firestore
            await setDoc(doc(db, 'dropdownDependencies', 'dynamicDependencies'), { dynamicDependencies: currentDependencies });


            // Refresh rows with updated dependencies
            const dependencies = await getDropdownDependencies();
            const updatedRows = rows.map(item => {

                // Update dependencies for the row that matches the source field
                if (item.label === sourceField) {
                    return {
                        ...item,
                        dependencies: dependencies.dynamicDependencies[item.label] || {}
                    };
                }
                return item;
            });
            setRows(updatedRows);
            onDataChange(category,updatedRows); // Notify parent component about the change
        } catch (error) {
            console.error('Error updating dependencies:', error);
        }
    };

    const toggleDeleteMode = () => {
        setIsDeleteMode((prevMode) => !prevMode);
    };

    const handleDeleteRow = async (rowId) => {
        const updatedRows = rows.filter(row => row.id !== rowId);
        setRows(updatedRows);

        // Transform rows before sending to parent
        onDataChange(category, transformRowsForFirestore(updatedRows));
    };


    const getColumns = () => {
        let columns = [
    {
        field: 'label', 
        headerName: 'Options', 
        width: 150, 
        editable: true, 
        flex: 1,
        renderCell: (params) => (
            <div style={{ color: params.row.isNewRow ? 'gray' : 'inherit' }}>
                {params.value}
            </div>
        )
    },
        { field: 'hint', headerName: 'Hint', width: 100, editable: true, flex: 1 },
    {
        field: 'actions',
        type: 'actions',
        headerName: 'Actions',
        width: 80,
        getActions: (params) => {
            // Check if the current row has defined dependencies
            const hasDependencies = params.row.dependencies && Object.keys(params.row.dependencies).length > 0;

            return [
                <GridActionsCellItem
                    icon={hasDependencies ? 
                          <AccountTreeFilledIcon style={{ fontSize: '14px', color: theme.palette.primary.main }} /> : 
                          <AccountTreeIcon style={{ fontSize: '14px' }} />}
                    label="Manage Dependencies"
                    onClick={() => handleOpenDependencyManager(params.row)}
                />
            ];
        },
    },
    ];

        if (isDeleteMode) {
            columns.push({
                field: 'delete',
                headerName: 'Delete',
                sortable: false,
                width: 80,
                disableColumnMenu: true, // Disable the column menu to prevent the tooltip
                renderCell: (params) => (
                    <GridActionsCellItem
                        icon={<DeleteIcon style={{ fontSize: '14px' }} />}
                        //showInMenu={true} // Add this line to prevent the tooltip
                        onClick={() => handleDeleteRow(params.id)}
                    />
                ),
            });
        }

        return columns;
    };

    return (
        <div style={{ height: 1200, width: '100%' }}>
            <DataGrid
                ref={dataGridRef} 
                className={styles.dataGrid}
                rows={rows}
                columns={getColumns()}
                pageSize={5}
                onCellEditStart={handleCellEditStart}
                components={{ Toolbar: CustomToolbar }}
                componentsProps={{
                    toolbar: {
                        title: title,
                        onAddRow: handleAddRow,
                        onToggleDelete: toggleDeleteMode
                    }
                }}    
                disableSelectionOnClick
                processRowUpdate={processRowUpdate}
                getRowClassName={(params) => params.row.dependencies && Object.keys(params.row.dependencies).length > 0 ? 'row-with-dependencies' : ''}
                rowHeight={35} // You can adjust this value as needed
                hideFooter={true} // Add this line to disable pagination
                sx={{
                    '& .MuiDataGrid-root': {
                        border: 0,
                        borderRadius: '0px !important',
                    },
                    '.MuiDataGrid-columnHeaders': {
                        backgroundColor: '#f5f5f5',
                    },
                    '& .MuiDataGrid-cell[data-field="hint"]': {
                        color: 'gray',
                    },
                }}
            />

            {openDependencyManager && (
                <DependencyManager
                    open={openDependencyManager}
                    onClose={handleCloseDependencyManager}
                    onSave={handleDependencyUpdate}
                    fields={allFields}
                    currentRow={currentRow}
                    optionsForDependentField={dropdownOptions}
                />
            )}
        </div>
    );
};

export default EditableTable;
