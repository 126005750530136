import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import EditableTable from '../components/DropdownManagement/EditableTable';
import { CircularProgress, Snackbar, Alert, Button, Typography, Box } from '@mui/material';
import { getDropdownOptions, updateDropdownOptions, getDropdownDependencies, updateDropdownDependencies } from '../utils/firestore';
import styles from './DropdownManagement.module.css';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

const DropdownManagement = () => { 
 // console.log("Rendering DropdownManagement component");

  const isProduction = process.env.NODE_ENV === 'production'; // Environment check
  const [dropdownOptions, setDropdownOptions] = useState({});
  const [dropdownDependencies, setDropdownDependencies] = useState({});
  const [loading, setLoading] = useState(false);
  const [saving, setSaving] = useState(false);
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [hasUnsavedChanges, setHasUnsavedChanges] = useState(false);

  const navigate = useNavigate();

  const handleBack = () => {
    navigate(-1); // Navigate to the previous page
  };

  useEffect(() => {
  //  console.log("useEffect triggered to fetch data");

    const fetchData = async () => {
      setLoading(true);
   //   console.log('Fetching data from Firebase...');
      try {
        const options = await getDropdownOptions();
        const dependencies = await getDropdownDependencies();

    //    console.log('Received dropdown options:', options);
    //    console.log('Received dropdown dependencies:', dependencies);

        setDropdownOptions(options);
        setDropdownDependencies(dependencies);
      } catch (err) {
        console.error('Error fetching data from Firebase:', err);
        setError('Failed to fetch data from the server.');
      } finally {
        setLoading(false);
      }
    };
    fetchData();
  }, []);

  const handleChange = (category, updatedData) => {
    if (!isProduction && Array.isArray(updatedData)) { // Check if not in production
        setDropdownOptions(prevOptions => {
            const newOptions = { ...prevOptions, [category]: updatedData };
            return newOptions;
        });
        setHasUnsavedChanges(true);
    } else if (isProduction) {
   //     console.log(`Editing disabled in production for '${category}'`);
    } else {
        console.error(`handleChange - Incorrect data type for '${category}'. Expected an array, received:`, typeof updatedData);
    }
  };

  const handleDataChange = (category, data) => {
    handleChange(category, data);
  }

  const handleSave = async () => {
    if (isProduction) return; // Prevent saving in production

    setSaving(true);
    try {
      for (const category in dropdownOptions) {
        if (Array.isArray(dropdownOptions[category])) {
          await updateDropdownOptions(category, dropdownOptions[category]);
        } else {
          console.error(`Data for category '${category}' is not an array:`, dropdownOptions[category]);
        }
      }

      if (typeof dropdownDependencies === 'object' && !Array.isArray(dropdownDependencies)) {
        await updateDropdownDependencies(dropdownDependencies);
      } else {
        console.error('Dependencies data structure is incorrect:', dropdownDependencies);
      }

      setSuccess('All changes saved successfully.');
      setHasUnsavedChanges(false);
    } catch (err) {
      console.error('Save error:', err);
      setError('Failed to save changes. ' + err.message);
    } finally {
      setSaving(false);
      setSnackbarOpen(true);
    }
  };

  const handleCloseSnackbar = () => {
    setSnackbarOpen(false);
  };

  if (loading) {
  //console.log("Loading data...");

    return <CircularProgress />;
  }

 // console.log("Data loaded:", { dropdownOptions, dropdownDependencies });


  return (
    <div>
      <div className={styles.headerSection}>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
          <Button
            variant="text"
            startIcon={<ArrowBackIcon />}
            style={{ padding: '0', marginRight: '8px' }}
            onClick={handleBack}
          >
            <Typography variant="text" component="span" className={styles.subHeader}>
              Back
            </Typography>
          </Button>

          <Box sx={{ display: 'flex', alignItems: 'center', marginRight: '16px' }}> 
            {saving && <CircularProgress size={24} style={{ marginRight: 15 }} />}
            <Button
              variant="contained"
              disabled={!hasUnsavedChanges || saving || isProduction}
              onClick={handleSave}
              style={{
                backgroundColor: hasUnsavedChanges ? '#FFA726' : '#E0E0E0',
                color: hasUnsavedChanges ? 'white' : '#A0A0A0',
                boxShadow: 'none',
                padding: '6px 16px',
                ':hover': {
                  backgroundColor: hasUnsavedChanges ? '#FFB74D' : '#E0E0E0',
                  boxShadow: 'none'
                }
              }}
            >
              {isProduction ? "Read Only" : "Save"}
            </Button>
          </Box>
        </Box>
      </div>
      

      <div className={styles.tablesContainer}>
        {Object.keys(dropdownOptions).map((category) => (
            <div key={category} className={styles.editableTable}>
            <EditableTable
              title={`${category}`}
              initialData={dropdownOptions[category] || []}
              onDataChange={handleDataChange}
              dependencyConfig={dropdownDependencies}
              category={category} 
              allFields={Object.keys(dropdownOptions)}
              readOnly={isProduction} // Added readOnly prop
            />
          </div>
        ))}
      </div>

      <Snackbar open={snackbarOpen} autoHideDuration={6000} onClose={handleCloseSnackbar}>
        <Alert onClose={handleCloseSnackbar} severity={error ? "error" : "success"} sx={{ width: '100%' }}>
          {error || success}
        </Alert>
      </Snackbar>
    </div>
  );
};

export default DropdownManagement;
