import React, { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import { FormControl, InputAdornment, IconButton, styled } from '@mui/material';
import ClearIcon from '@mui/icons-material/Clear';

const OptionContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  width: '100%',
  '& > span': {
    fontSize: '0.875rem',
  },
}));

const HintText = styled('span')(({ theme }) => ({
  color: theme.palette.grey[500],
}));

const CustomAutocomplete = styled(Autocomplete)(({ theme, disabled }) => ({
  ...(disabled && {
    '& .MuiInputBase-root': {
      backgroundColor: theme.palette.action.disabledBackground,
    },
    '& .MuiInputBase-input': {
      color: theme.palette.text.disabled,
    },
  }),
}));

const DropdownFieldComponent = ({ label, options, dynamicOptions, value, onChange, hint, helperText, required, submitted, includeOther = false, disabled = false }) => {
  const [inputValue, setInputValue] = useState('');
  const inputRef = useRef(null);

  // Update the options when dynamicOptions change
  useEffect(() => {
    if (dynamicOptions && dynamicOptions.length > 0) {
      setInputValue(''); // Reset the input value if the options change dynamically
    }
  }, [dynamicOptions]);

  useEffect(() => {
    if (inputValue === 'Other' && inputRef.current) {
      inputRef.current.focus();
    }
  }, [inputValue]);

  const handleAutocompleteChange = (_, newValue) => {
    if (newValue?.label === 'Other') {
      setInputValue('Other');
      onChange({ label: '' }); // Clear the value when 'Other' is selected
    } else {
      setInputValue('');
      onChange(newValue);
    }
  };

  const handleTextFieldChange = (event) => {
    onChange({ label: event.target.value });
  };

  const handleBlur = () => {
    if (inputValue === 'Other' && !value?.label) {
      setInputValue('');
    }
  };

  const handleClear = () => {
    setInputValue('');
    onChange(null);
  };

  const actualOptions = Array.isArray(dynamicOptions) ? dynamicOptions 
                    : Array.isArray(options) ? options 
                    : [];
                      
  const isValidValue = actualOptions.some(option => option.label === value?.label) || (includeOther && value?.label);
  const showError = submitted && required && !isValidValue;
  const formattedLabel = required ? `${label} *` : label;
  const dropdownOptions = includeOther ? [...actualOptions, { label: 'Other' }] : actualOptions;

  return (
    <FormControl fullWidth required={required} error={showError}>
      {inputValue === 'Other' ? (
        <TextField
          label={formattedLabel}
          value={value?.label || ''}
          onChange={handleTextFieldChange}
          onBlur={handleBlur}
          helperText={showError ? 'This field is required' : helperText}
          error={showError}
          inputRef={inputRef}
          variant="outlined"
          disabled={disabled}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                {inputValue === 'Other' && (
                  <IconButton edge="end" onClick={handleClear} size="small">
                    <ClearIcon />
                  </IconButton>
                )}
              </InputAdornment>
            ),
          }}
        />
      ) : (
        <CustomAutocomplete
          options={dropdownOptions}
          value={dropdownOptions.find(option => option.label === value?.label) || null}
          onChange={handleAutocompleteChange}
          renderInput={(params) => (
            <TextField
              {...params}
              label={formattedLabel}
              placeholder={hint || ''}
              helperText={showError ? 'This field is required' : helperText}
              error={showError}
              disabled={disabled}
              InputProps={{
                ...params.InputProps,
                endAdornment: (
                  <>
                    {value?.label && (
                      <IconButton edge="end" onClick={handleClear} size="small">
                        <ClearIcon />
                      </IconButton>
                    )}
                    {params.InputProps.endAdornment}
                  </>
                ),
              }}
            />
          )}
          renderOption={(props, option) => (
            <li {...props}>
              <OptionContainer>
                <span>{option.label}</span>
                {option.hint && <HintText>{option.hint}</HintText>}
              </OptionContainer>
            </li>
          )}
          disableClearable
          autoHighlight
          autoSelect
          fullWidth
          isOptionEqualToValue={(option, val) => option.label === val?.label}
          disabled={disabled}
        />
      )}
    </FormControl>
  );
};

DropdownFieldComponent.propTypes = {
  label: PropTypes.string.isRequired,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      hint: PropTypes.string,
    })
  ).isRequired,
  dynamicOptions: PropTypes.array, // New prop type for dynamic options
  value: PropTypes.shape({
    label: PropTypes.string.isRequired,
    hint: PropTypes.string,
  }),
  onChange: PropTypes.func.isRequired,
  hint: PropTypes.string,
  helperText: PropTypes.string,
  required: PropTypes.bool,
  submitted: PropTypes.bool,
  includeOther: PropTypes.bool,
  disabled: PropTypes.bool,
};

export default DropdownFieldComponent;
