import { initializeApp } from 'firebase/app';
import { getFirestore } from 'firebase/firestore';

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyB7dmPnmI0Co2bZgVy71ujdELaBmmi0dGo",
  authDomain: "adobeutmsmartmanager.firebaseapp.com",
  projectId: "adobeutmsmartmanager",
  storageBucket: "adobeutmsmartmanager.appspot.com",
  messagingSenderId: "827277777286",
  appId: "1:827277777286:web:6057620a237fe0d6177407",
  measurementId: "G-ZYRZ10XFWN"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

// Initialize Firestore
const db = getFirestore(app);

export { db };