import React, { useEffect } from 'react';
import { ThemeProvider, CssBaseline } from '@mui/material';
import { BrowserRouter as Router, Routes, Route, useLocation } from 'react-router-dom';
import theme from './styles/theme';
import NavbarComponent from './components/Navbar/NavbarComponent';
import FooterComponent from './components/Footer/FooterComponent';
import HomePage from './pages/Home';
import DropdownManagement from './pages/DropdownManagementPage';

// Custom hook for tracking page views
const usePageTracking = () => {
  const location = useLocation();

  useEffect(() => {
    // Check if the dataLayer and GTM are initialized
    if (window.dataLayer && typeof window.dataLayer.push === 'function') {
      // Pushing data to the GTM dataLayer with the new page path
      window.dataLayer.push({
        event: 'pageview',
        page: location.pathname + location.search,
      });
    } else {
      console.error('GTM dataLayer is not initialized');
    }
  }, [location]);
};

const RouteChangeTracker = () => {
  usePageTracking();
  return null;
};

const App = () => {
  const isInIframe = () => {
    return window.location !== window.parent.location;
  };

  useEffect(() => {
    // Trigger a pageview event for the initial page load
    if (window.dataLayer && typeof window.dataLayer.push === 'function') {
      window.dataLayer.push({
        event: 'pageview',
        page: window.location.pathname + window.location.search,
      });
    }
  }, []);

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Router>
        <RouteChangeTracker /> {/* Include the tracker here */}
        {!isInIframe() && <NavbarComponent />}
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="/manage-dropdowns" element={<DropdownManagement />} />
          {/* Add other routes as needed */}
        </Routes>
        {!isInIframe() && <FooterComponent />}
      </Router>
    </ThemeProvider>
  );
};

export default App;
