import React, { useState } from 'react';
import { Snackbar, Alert, Button, styled } from '@mui/material';

const StyledAlert = styled(Alert)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  '& .MuiAlert-icon': {
    fontSize: '1.25rem',
    lineHeight: '1.25',
    marginRight: theme.spacing(1),
    color: '#ffffff',
  },
  borderRadius: '0',
}));

const StyledSnackbar = styled(Snackbar)({
  '& .MuiSnackbarContent-root': {
    justifyContent: 'center',
  },
  top: 0, // Add this to ensure Snackbar sticks to the top
});

const messageStyle = {
  // flex: 1, // Remove flex property
  textAlign: 'center',
  fontWeight: 300,
  fontFamily: 'Roboto, sans-serif',
  // display: 'flex', // Remove display: flex
  // alignItems: 'center', // Remove alignItems: center
  // overflow: 'hidden', // Remove overflow: hidden
  // textOverflow: 'ellipsis', // Remove textOverflow: ellipsis
};

const SnackbarBanner = ({ message, action }) => {
  const [open, setOpen] = useState(true);

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
  };

  return (
    <StyledSnackbar
      anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      open={open}
      onClose={handleClose}
      sx={{ width: '100%' }}
    >
      <StyledAlert
        onClose={handleClose}
        severity="info"
        sx={{ 
          width: '100%', 
          backgroundColor: '#1a73e8',
          color: '#ffffff',
        }} 
      >
        <span style={messageStyle}>{message}</span>
        {action && (
          <Button 
            color="secondary" 
            size="small" 
            onClick={action.onClick} 
            sx={{ color: '#ffffff', fontWeight: 'bold', textTransform: 'none' }}
          >
            {action.label}
          </Button>
        )}
      </StyledAlert>
    </StyledSnackbar>
  );
};

export default SnackbarBanner;
