import React from 'react';

const UTMGenerator = ({ utmParams, children }) => {
  // Ensure these match the keys you have set in the utmParams state object
  const { landingPageUrl, utm_source, utm_medium, utm_objective, utm_campaign, utm_content, utm_term, utm_date } = utmParams;

  let utm = '';
  if (landingPageUrl && utm_source && utm_medium && utm_campaign) {
    // Create an array with the order and structure of UTM parameters
    const utmStructure = [
      `cid=${encodeURIComponent(utm_source)}`,
      encodeURIComponent(utm_medium),
      encodeURIComponent(utm_objective),
      encodeURIComponent(utm_campaign),
      encodeURIComponent(utm_content),
      encodeURIComponent(utm_term),
      encodeURIComponent(utm_date),
    ].filter(Boolean).join(':'); // Ensure the separator is as per your Adobe Campaign URL structure

    utm = `${landingPageUrl}?${utmStructure}`;
  }

  // Render children with the generated UTM
  return <>{children(utm)}</>;
};

export default UTMGenerator;
